<template>
    <BasePageLoader :isLoading="isFetchingData">
        <div class="col q-col-gutter-x-md fit q-py-lg">
            <div
                class="row q-col-gutter-md justify-between items-center q-mb-md"
            >
                <BaseInput
                    class="col-sm-9 col-xs-12"
                    label="Поиск"
                    :debounce="500"
                    :modelValue="searchFilterValue"
                    @update:modelValue="updateSearchFilterValue"
                    dense
                    filled
                    clearable
                />
                <div
                    class="row col-sm col-xs-12 q-col-gutter-x-md justify-end items-center"
                >
                    <q-pagination
                        class="col-sm-9 col-xs-8 justify-end"
                        :modelValue="currentPage"
                        @update:modelValue="updateCurrentPage"
                        color="primary"
                        :max="maxNumberOfPages"
                        :max-pages="6"
                        input
                    />
                    <BaseSelect
                        class="col"
                        :options="SHOPS_ITEMS_PER_PAGE"
                        :modelValue="itemsPerPage"
                        dense
                        borderless
                        @update:modelValue="updateItemsPerPage"
                    />
                </div>
            </div>
            <BaseLoaderOverlay :isLoading="isFetchingShops">
                <div>
                    <div class="col row q-mb-sm items-end">
                        <div class="col text-bold">
                            Всего результатов: {{ totalCount }}
                        </div>
                        <div class="col-2">
                            <BaseSelect
                                label="Статус"
                                :options="shopStatusOptions"
                                :modelValue="selectedStatus"
                                @update:modelValue="updateSelectedStatus"
                                dense
                            />
                        </div>
                    </div>
                    <q-list
                        bordered
                        separator
                        v-if="shops.length"
                        class="shops"
                    >
                        <q-item
                            v-for="shop in shops"
                            :key="shop"
                            class="items-center shops__list-item"
                            clickable
                            v-ripple
                            :to="getReportLink(shop.token)"
                        >
                            <BaseShopStatus
                                class="shops__list-item-status"
                                :status="shop.status"
                            />
                            {{ getShopFullName(shop) }}
                            <VSettingsPopup :storeToken="shop.token" />
                        </q-item>
                    </q-list>
                    <div class="text-center text-h6 q-mt-lg" v-else>
                        Магазины не найдены
                    </div>
                </div>
            </BaseLoaderOverlay>
        </div>
    </BasePageLoader>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { useShopsStore } from '@/stores/shops'
import { getShopFullName } from '@/utils/shop'
import { onBeforeMount, ref, watch, watchEffect } from 'vue'
import { SHOPS_ITEMS_PER_PAGE, SHOP_STATUS_OPTIONS } from '@/constants/shop'
import VSettingsPopup from '@/components/VSettingsPopup.vue'

export default {
    name: 'Shops',
    components: { VSettingsPopup },
    setup() {
        const shopsStore = useShopsStore()
        const {
            shops,
            searchFilterValue,
            currentPage,
            totalCount,
            isFetchingShops,
            itemsPerPage,
            maxNumberOfPages,
            selectedStatus,
        } = storeToRefs(shopsStore)

        const {
            fetchShops,
            updateSearchFilterValue,
            updateCurrentPage,
            updateItemsPerPage,
            updateSelectedStatus,
        } = shopsStore

        const isFetchingData = ref(true)
        const router = useRouter()
        const route = useRoute()

        const getReportLink = (storeToken) => `/report/${storeToken}`

        watchEffect(() => {
            if (route.name !== 'Shops') return

            router.replace({
                name: 'Shops',
                query: {
                    page: currentPage.value,
                    itemsPerPage: itemsPerPage.value,
                    searchQuery: searchFilterValue.value,
                    notReadyOnly:
                        selectedStatus.value ===
                            SHOP_STATUS_OPTIONS.NOT_READY || '',
                },
            })
        })

        watch(
            [currentPage, itemsPerPage, searchFilterValue, selectedStatus],
            fetchShops,
        )

        onBeforeMount(async () => {
            const { page, itemsPerPage, searchQuery, notReadyOnly } =
                route.query

            if (page) {
                updateCurrentPage(Number(page))
            }

            if (itemsPerPage) {
                updateItemsPerPage(Number(itemsPerPage))
            }

            if (searchQuery) {
                updateSearchFilterValue(searchQuery)
            }

            if (notReadyOnly) {
                updateSelectedStatus(SHOP_STATUS_OPTIONS.NOT_READY)
            }

            try {
                await fetchShops()
            } catch (error) {
                if (error.response.status === 401) {
                    router.push({ name: 'Login' })
                }
            } finally {
                isFetchingData.value = false
            }
        })

        const shopStatusOptions = Object.values(SHOP_STATUS_OPTIONS)

        return {
            shops,
            searchFilterValue,
            getShopFullName,
            updateSearchFilterValue,
            getReportLink,
            isFetchingData,
            currentPage,
            updateCurrentPage,
            totalCount,
            isFetchingShops,
            maxNumberOfPages,
            SHOPS_ITEMS_PER_PAGE,
            updateItemsPerPage,
            itemsPerPage,
            shopStatusOptions,
            selectedStatus,
            updateSelectedStatus,
        }
    },
}
</script>

<style lang="scss" scoped>
.shops {
    &__list-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-status {
            position: absolute;
            right: 4.5em;
        }
    }
}
</style>
