<template>
    <q-btn color="black" :icon="icon" flat round dense @click.stop.prevent>
        <q-menu>
            <q-list>
                <q-item
                    clickable
                    v-close-popup
                    tag="a"
                    target="_blank"
                    :href="storeEditorLink"
                >
                    <q-item-section> Настройка </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script>
export default {
    name: 'VSettingsPopup',
    props: {
        icon: {
            type: String,
            default: 'more_vert',
        },
        storeToken: {
            type: String,
            required: true,
        },
    },
    computed: {
        storeEditorLink() {
            return `${process.env.VUE_APP_STORE_EDITOR_URL}management/stores/${this.storeToken}`
        },
        diagnosticsLink() {
            return `/diagnostics/${this.storeToken}`
        },
    },
}
</script>
