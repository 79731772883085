import { ShopsApi } from '@/api/services'
import { SHOPS_ITEMS_PER_PAGE, SHOP_STATUS_OPTIONS } from '@/constants/shop'
import { getShopFullName } from '@/utils/shop'
import { defineStore } from 'pinia'
import { logError } from '@/utils/common/index'

export const useShopsStore = defineStore('shops', {
    state: () => ({
        shops: [],
        selectedStoreToken: null,
        searchFilterValue: '',
        currentPage: 1,
        itemsPerPage: SHOPS_ITEMS_PER_PAGE[0],
        totalCount: 0,
        selectedShopData: {},
        selectedStatus: SHOP_STATUS_OPTIONS.ALL,
    }),
    getters: {
        maxNumberOfPages(state) {
            return Math.ceil(state.totalCount / state.itemsPerPage) || 1
        },
        shopOptions(state) {
            const shops = state.shops.map((shop) => ({
                label: getShopFullName(shop),
                value: shop.token,
            }))

            if (!state.selectedShopData.token || state.searchFilterValue) {
                return shops
            }

            if (
                !shops.find(({ value }) => value === state.selectedStoreToken)
            ) {
                shops.push({
                    label: getShopFullName(state.selectedShopData),
                    value: state.selectedStoreToken,
                })
            }

            return shops
        },
        shop(state) {
            return this.shopOptions.find(
                ({ value }) => value === state.selectedStoreToken,
            )
        },
        selectedShopTimeOffset() {
            return this.selectedShopData.timeOffset
        },
    },
    actions: {
        async getShops() {
            this.isFetchingShops = true

            try {
                const { data } = await ShopsApi.getShops({
                    page: this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    searchQuery: this.searchFilterValue,
                    notReadyOnly:
                        this.selectedStatus ===
                            SHOP_STATUS_OPTIONS.NOT_READY || null,
                })

                return data
            } finally {
                this.isFetchingShops = false
            }
        },
        async fetchShops() {
            const { stores = [], totalCount = 0 } = await this.getShops()

            this.totalCount = totalCount

            this.shops = stores
        },
        async loadMoreShops() {
            const { stores = [], totalCount = 0 } = await this.getShops()

            this.totalCount = totalCount
            this.shops.push(...stores)
        },
        async fetchSelectedShopData() {
            try {
                const { data } = await ShopsApi.getShop({
                    storeToken: this.selectedStoreToken,
                })

                this.selectedShopData = data
            } catch (error) {
                logError(error)
            }
        },
        updateSearchFilterValue(value) {
            this.searchFilterValue = value
        },
        updateCurrentPage(page) {
            if (page <= 0) return

            this.currentPage = page
        },
        updateItemsPerPage(itemsPerPage) {
            if (!SHOPS_ITEMS_PER_PAGE.includes(itemsPerPage)) return

            this.itemsPerPage = itemsPerPage
        },
        updateSelectedStoreToken(selectedStoreToken) {
            this.selectedStoreToken = selectedStoreToken
        },
        updateSelectedStatus(selectedStatus) {
            this.selectedStatus = selectedStatus
        },
    },
})
