/**
 * Returns shop name
 * @param {Shop} shop
 * @returns {String} shop name
 */
export const getShopFullName = (shop) => {
    const nameParts = [shop.retailChainName]

    if (shop.exportStoreId) {
        nameParts.push(`№${shop.exportStoreId}`)
    }

    if (shop.name) {
        nameParts.push(`(${shop.name})`)
    }

    const fullAddress = getShopAddress(shop)

    if (fullAddress) {
        nameParts.push(`(${fullAddress})`)
    }

    return nameParts.join(' ')
}

/**
 * Returns combined shop address
 * @param {Shop} shop
 * @returns {String} shop address
 */
export const getShopAddress = ({
    addressCity,
    addressRoad,
    addressHouseNumber,
}) => {
    return [addressCity, addressRoad, addressHouseNumber]
        .filter((v) => v)
        .join(', ')
}
